.loading-product {
  text-align: center;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.productpage {
  margin-top: 70px;
}
.card-wrapper {
  max-width: 1100px;
  margin: 120px auto;
  margin-top: 130px;
}
.img-display {
  overflow: hidden;
}
.img-showcase {
  display: flex;
  width: 100%;
  transition: all 0.5s ease;
}
.img-showcase img {
  min-width: 100%;
}
.img-select {
  display: flex;
  justify-content: center;
  height: 200px;
}
.img-item {
  margin: 0.3rem;
}
.img-item:nth-child(1),
.img-item:nth-child(2),
.img-item:nth-child(3) {
  margin-right: 0;
}
.img-item:hover {
  opacity: 0.8;
}

.product-content {
  padding: 2rem 1rem;
}
.REVIEWS {
  margin: 40px;
}
.REVIEWS h1 {
  font-size: 20px;
  font-weight: 700;
  margin: 20px 0px;
}
.review h4 {
  font-size: 16px;
  font-weight: 600;
  margin: 8px;
}
.commentreview {
  margin: 8px;
}

.datereview,
.Ratingreview {
  justify-self: end;
  margin: 8px;
}
.review {
  display: grid;
  height: 100px;
  grid-template-columns: 1fr 1fr;
  background-color: rgb(243, 243, 243);
  border-bottom: #12263a 2px solid;
  margin: 0px 5px;
}
.product-title {
  font-size: 40px;
  text-transform: capitalize;
  font-weight: 700;
  position: relative;
  color: #12263a;
  margin: 1rem 0;
}
.product-title::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  width: 80px;
  background: #12263a;
}
.product-link {
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.9rem;
  display: inline-block;
  margin-bottom: 0.5rem;
  color: #582914;
  padding: 0 0.3rem;
  transition: all 0.5s ease;
}
.product-link:hover {
  opacity: 0.9;
}
.product-rating {
  display: flex;
}
.product-rating i {
  color: #ffc107;
}
.product-rating span {
  font-weight: 600;
  color: #070706;
}
.productpricecard {
  align-self: flex-start;
  margin-top: 10px;
}
.product-price {
  margin: 1rem 0;
  font-size: 1rem;
  font-weight: 700;
}
.product-price span {
  font-weight: 400;
}
.last-price span {
  color: #f64749;
  text-decoration: line-through;
}
.new-price span {
  color: #256eff;
}
.product-detail h2 {
  
  color: #12263a;
  padding-bottom: 0.6rem;
}
.product-detail p {
  font-size: 0.9rem;
  padding: 0.3rem;
  opacity: 0.8;
}
.product-detail ul {
  margin: 1rem 0;
  font-size: 0.9rem;
}
.select-product {
  margin-left: 20px;
}
.color-product ul {
  display: flex;
}

.color-product ul li {
  margin: 0;

  list-style: none;
  background: url("./checked.png") left center no-repeat;
  background-size: 18px;
  padding-left: 1.7rem;
  margin: 0.4rem 0;
  font-weight: 600;
  opacity: 0.9;
}
.product-detail ul li {
  margin: 0;
  list-style: none;
  background: url("./bullet.png") left center no-repeat;
  background-size: 18px;
  padding-left: 1.7rem;
  margin: 0.4rem 0;
  font-weight: 600;
  opacity: 0.9;
}
.product-detail ul li span {
  font-weight: 400;
}
.purchase-info {
  display: flex;
  margin: 1.5rem 0;
}
.purchase-info input,
.purchase-info .btn-shop {
  display: flex;
  border: 1.5px solid #ddd;
  border-radius: 25px;
  text-align: center;
  padding: 0.45rem 0.8rem;
  outline: 0;
  margin-right: 0.2rem;
  margin-bottom: 1rem;
}
.purchase-info input {
  width: 60px;
}
.purchase-info .btn-shop {
  cursor: pointer;
  color: #fff;
}
.purchase-info .btn-shop {
  background: rgb(113, 127, 224);
}

.purchase-info .btn-shop:hover {
  background: rgb(59, 74, 168);
}
.social-links {
  display: flex;
  align-items: center;
}
.social-links .social {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  color: #000;
  border: 1px solid #12263a;
  margin: 0 0.2rem;
  border-radius: 50%;
  text-decoration: none;
  font-size: 0.8rem;
  transition: all 0.5s ease;
}
.social-links .social:hover {
  background: #12263a;
  border-color: transparent;
  color: #fff;
}
@media screen and (min-width: 992px) {
  .card {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem;
  }
  .card-wrapper {
    /* height: 100vh; */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .product-imgs {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .product-content {
    padding-top: 0;
  }
}
