
.cardProduct {
    box-shadow: 0 5px 5px -1px rgba(0, 0, 0, 0.2);
    display: grid;
    grid-template-rows: 80% 20%;
    width: 300px;
    height: 430px;
    justify-self: center;
    background-position: center;
    position: relative;
  }
/* promotion label over product */
.cardProduct .promotion {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.5rem 1rem;
  background-color: #f44336;
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  z-index: 1;
}
